import React from "react";
import { View, TextInput } from "react-native";

import styles from "../utils/styles";

export default class ForageSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textInput: "",
    };
  }

  render() {
    return (
      <View style={styles.ForageSearchBarView}>
        <TextInput
          style={styles.ForageSearchBarTextInput}
          multiline={false}
          // autoCapitalize="none"
          autoCorrect={true}
          keyboardType="default"
          returnKeyType="search"
          placeholder={
            this.props.placeholder ? this.props.placeholder : "Search"
          }
          onChangeText={(inputText) => {
            this.setState({ textInput: inputText });
          }}
          onSubmitEditing={() => {
            this.props.onSubmitEditing(this.state.textInput);
          }}
        />
      </View>
    );
  }
}
