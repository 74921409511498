// "use strict";

// Sources:
// https://stackoverflow.com/a/39121967/11244775
// https://stackoverflow.com/a/40113934/11244775

import React, { Component } from "react";
import { ReactNative, Dimensions } from "react-native";
import ForageButton from "./ForageButton";
import styles from "../utils/styles";

import { StyleSheet, View, Animated } from "react-native";

var isHidden = true;
const diffHight = Dimensions.get('window').height;

export default class ForageSlideUpPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bounceValue: new Animated.Value(diffHight), //This is the initial position of the subview
    };
    this.toggleSubView = this.toggleSubView.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isHidden != prevProps.isHidden) {
      this.toggleSubView();
    }
  }

  toggleSubView() {
    var toValue = diffHight;

    if (isHidden) {
      toValue = 0;
    }

    //This will animate the transalteY of the subview between 0 & 100 depending on its current state
    //100 comes from the style below, which is the height of the subview.
    Animated.spring(this.state.bounceValue, {
      toValue: toValue,
      velocity: 3,
      tension: 2,
      friction: 8,
    }).start();

    isHidden = !isHidden;
  }

  render() {
    return (
      <View style={stylesLocal.container}>
        <Animated.View
          style={[
            stylesLocal.subView,
            { transform: [{ translateY: this.state.bounceValue }] },
          ]}
        >
          <ForageButton
            style={{}}
            icon="close-circle-outline"
            onPress={this.toggleSubView}
          />
          <View style={{padding: 5}} />
          {this.props.children}
        </Animated.View>
      </View>
    );
  }
}

var stylesLocal = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  subView: {
    flex: 1,
    width: "95%",
    position: "absolute",
    bottom: 0,
    // left: 0,
    // right: 0,
    backgroundColor: "#FFFFFF",
    // height: diffHight,
    // height: 100,
    // width: Math.min(350, width*0.85),
    // justifyContent: 'center',
    // flexDirection: 'column',
    // alignItems: 'center',
    backgroundColor: "white",
    paddingLeft: 10,
    // paddingBottom: 10,
    paddingTop: 10,
    paddingRight: 10,
    borderWidth: 0.25,
    borderRadius: 10,
  },
});
