
/*
Forage Default location if one cannot be found
*/ 

const locationConst = {
    default_address_str: "Federal Hill-Montgomery Baltimore, MD",
    latitude: 39.2799587,
    longitude: -76.6126724,
}

export default locationConst;
