import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import { MaterialCommunityIcons } from "react-native-vector-icons";
import styles from "../utils/styles";
import styleConst from "../utils/styleConst";

// https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg

const ForageButton = (props) => {
  return (
    <TouchableOpacity
      style={
        props.style
          ? props.style
          : props.color
          ? [styles.ForageButtonView, { backgroundColor: props.color }]
          : styles.ForageButtonView
      }
      onPress={props.onPress}
    >
      <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
        {props.icon ? (
          <MaterialCommunityIcons
            name={props.icon}
            size={20}
            style={props.iconStyle ? props.iconStyle : { paddingRight: 10 }}
          />
        ) : (
          <View />
        )}
        <Text
          style={[
            styles.itemText,
            {
              color: props.fontColor ? props.fontColor : styleConst.forageBlack,
            },
          ]}
        >
          {props.title ? (props.icon ? "  " : "") + props.title : ""}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default ForageButton;
