import React from "react";
import { View, Text, SafeAreaView, ScrollView } from "react-native";
import * as Location from "expo-location";

import Fire from "../../utils/Firebase";
import locationConst from "../../utils/locationConst";
import styles from "../../utils/styles";
import styleConst from "../../utils/styleConst";

import ForageAlert from "../../elements/ForageAlert";
import ForageButton from "../../elements/ForageButton";
import ForageSearchBar from "../../elements/ForageSearchBar";
import ForageSlideUpPanel from "../../elements/ForageSlideUpPanel";

/*
Address Data Format:
      addressData: {
        "address": {
            "formattedAddress": "...",
            "postalAddress": {
                "regionCode": "US",
                "languageCode": "en",
                "postalCode": "#####-####",
                "administrativeArea": "State",
                "locality": "City",
                "addressLines": [
                    "...."
                ]
            },
        },
        "geocode": {
            "location": {
                "latitude": 39.096877,
                "longitude": -76.9215302
            },
        },
      }
*/

export default class LocationChangeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAddress: undefined,
      currentAddressData: undefined,
      latitude: undefined,
      longitude: undefined,

      isAddressPanelHidden: true,
      searchAddress: undefined,
      searchAddressData: undefined,
    };
    this.searchAddressStr = this.searchAddressStr.bind(this);

    this.UpdateAddress = this.UpdateAddress.bind(this);
    this.GetCurrentLocation = this.GetCurrentLocation.bind(this);

    this.updateSearchAddress = this.updateSearchAddress.bind(this);
    this.updateCurrentAddress = this.updateCurrentAddress.bind(this);
  }

  componentDidMount() {
    console.log("Location Change Screen mounted...");
    if (this.props.currentAddress) {
      this.setState({ currentAddress: this.props.currentAddress });
    } else {
      // TODO: Check if location history, or current location is available.
      this.setState({ currentAddress: locationConst.default_address_str });
    }
  }

  updateSearchAddress(addressStr, addressData) {
    this.setState({
      searchAddress: addressStr,
      searchAddressData: addressData,
    });
  }

  updateCurrentAddress(addressStr, addressData) {
    this.setState({
      currentAddress: addressStr,
      currentAddressData: addressData,
    });
    if (this.props.route) {
      if (this.props.route.params) {
        if (this.props.route.params.updateCurrentAddress) {
          this.props.route.params.updateCurrentAddress(addressStr);
        }
        if (this.props.route.params.updateCurrentAddressData) {
          this.props.route.params.updateCurrentAddressData(addressData);
        }
      }
    }
  }

  searchAddressStr(address) {
    Fire.shared.address_validation(address).then((result) => {
      if (result.address) {
        if (result.address.formattedAddress) {
          this.updateSearchAddress(result.address.formattedAddress, result);
        }
      } else {
        this.updateSearchAddress("Unable to find this address.", undefined);
      }
    });
  }

  GetCurrentLocation = async () => {
    let { status } = await Location.requestForegroundPermissionsAsync();
    console.log("status: ");
    console.log(status);
    if (status != "granted") {
      ForageAlert(
        "Alert",
        "Permission to access location was denied.\nUsing " +
          locationConst.default_address_str,
        []
      );
    } else {
      let user_location = await Location.getCurrentPositionAsync({});
      console.log("user location: ");
      console.log(user_location);
    }
  };

  UpdateAddress(address) {
    Fire.shared.address_validation(address).then((result) => {
      console.log("Update Address: ");
      if (result.geocode && result.address) {
        console.log("Update Address to " + result.address.formattedAddress);
        this.setState({
          latitude: result.geocode.location.latitude,
          longitude: result.geocode.location.longitude,
          currentAddress: result.address.formattedAddress,
        });
      }
    });
  }

  render() {
    return (
      <SafeAreaView style={styles.SafeAreaViewStyle}>
        <ScrollView
          contentContainerStyle={{ justifyContent: "center" }}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <View style={{ alignContent: "center", justifyContent: "center" }}>
            <Text
              style={{
                color: styleConst.foragePurple,
                fontSize: 16,
                fontFamily: "FredokaOne",
                textAlign: "left",
                paddingLeft: 10,
              }}
            >
              Current address:
            </Text>
            <Text style={{ paddingLeft: 10 }}>{this.state.currentAddress}</Text>

            {/* Search */}
            <ForageSearchBar
              placeholder="Search for an address"
              onSubmitEditing={async (searchStr) => {
                this.searchAddressStr(searchStr);
                this.setState({
                  isAddressPanelHidden: !this.state.isAddressPanelHidden,
                });
              }}
            />

            {/* Current Location Button */}
            {/* <View style={{ alignItems: "center" }}>
              <ForageButton
                color={styleConst.foragePurple}
                fontColor="white"
                icon="map-marker"
                title="Use current location"
                iconStyle={{ color: "white", paddingRight: 10 }}
                onPress={this.GetCurrentLocation}
              />
            </View> */}
          </View>
        </ScrollView>

        {/* Suggested address panel */}
        <ForageSlideUpPanel isHidden={this.state.isAddressPanelHidden}>
          <Text style={styles.foragePrivacyPolicyHeader1}>
            Search Address:{" "}
          </Text>
          <Text>{this.state.searchAddress}</Text>
          <View style={[{ paddingVertical: 10 }]}>
            <ForageButton
              title="Use this address"
              onPress={() => {
                this.updateCurrentAddress(
                  this.state.searchAddress,
                  this.state.searchAddressData
                );
                this.setState({
                  isAddressPanelHidden: !this.state.isAddressPanelHidden,
                });
              }}
            />
          </View>
        </ForageSlideUpPanel>
      </SafeAreaView>
    );
  }
}
