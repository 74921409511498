import * as React from "react";
import { Component } from "react";
import { Text, View, SafeAreaView, ScrollView } from "react-native";

import Fire from "../../utils/Firebase";
import styles from "../../utils/styles";

import RestaurantList from "../../elements/RestaurantList";
import DistanceSlider from "../../elements/DistanceSlider";
import locationConst from "../../utils/locationConst";

import ForageButton from "../../elements/ForageButton";
import ForageSlideUpPanel from "../../elements/ForageSlideUpPanel";
import ForageSearchBar from "../../elements/ForageSearchBar";

const DEFAULT_ADDRESS = locationConst.default_address_str;

export default class RestaurantHomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // distance is in range from 0 - 50,000 meters for BackEnd APIs
      // using the 1600 - 8000 i.e. 1 - 5 miles.
      //    1 meter = 1/1609 mile
      // Price Range filter 1 - 4
      distance: 8000,
      cart_estimate: 4,
      query: undefined,

      // Location Data
      currentAddress: DEFAULT_ADDRESS,
      currentAddressData: undefined,
      latitude: undefined,
      longitude: undefined,

      // Hidden Panels
      isPriceFilterHidden: true,
      isSearchFilterHidden: true,
      isDistanceFilterHidden: true,
    };
    this.UpdateAddress = this.UpdateAddress.bind(this);
    this.UpdateRestaurantQuery = this.UpdateRestaurantQuery.bind(this);

    this.updateCurrentAddress = this.updateCurrentAddress.bind(this);
    this.updateCurrentAddressData = this.updateCurrentAddressData.bind(this);

    this.metersToMiles = this.metersToMiles.bind(this);
    this.milesToMeters = this.milesToMeters.bind(this);
  }

  componentDidMount() {
    console.log("RestaurantHomeScreen Mounted.");
    this.UpdateAddress(this.state.currentAddress);
  }

  updateCurrentAddress(newAddress) {
    this.setState({ currentAddress: newAddress });
  }

  updateCurrentAddressData(newAddressData) {
    this.setState({ currentAddressData: newAddressData });
  }

  UpdateAddress(address) {
    Fire.shared.address_validation(address).then((result) => {
      if (result.geocode && result.address) {
        this.setState({
          latitude: result.geocode.location.latitude,
          longitude: result.geocode.location.longitude,
        });
        this.updateCurrentAddress(result.address.formattedAddress);
        this.updateCurrentAddressData(result);
      }
    });
  }

  UpdateRestaurantQuery(new_query) {
    this.setState({ query: new_query });
    this.setState({ isSearchFilterHidden: !this.state.isSearchFilterHidden });
  }

  metersToMiles(meters) {
    return Math.round(meters * (1 / 1609));
  }

  milesToMeters(miles) {
    return Math.round(miles * 1609);
  }

  render() {
    return (
      <SafeAreaView style={styles.SafeAreaViewStyle}>
        <ScrollView
          style={styles.ScrollViewStyle}
          contentContainerStyle={{ justifyContent: "center" }}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          <SafeAreaView>
            {/* Display Current Address */}
            <Text style={styles.foragePrivacyPolicyHeader1}>
              {this.state.currentAddress}
            </Text>

            {/* Display current query */}
            {this.state.query ? (
              <Text style={styles.foragePrivacyPolicyHeader1}>
                {this.state.query}
              </Text>
            ) : (
              <View />
            )}
            <ScrollView
              horizontal={true}
              contentContainerStyle={{
                justifyContent: "space-between",
                padding: 10,
                gap: 5,
              }}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
            >
              <ForageButton
                style={styles.ForageButtonNonFlexView}
                icon="map-marker"
                title="Location"
                onPress={() => {
                  this.props.navigation.navigate("Location Change", {
                    updateCurrentAddress: this.UpdateAddress,
                    updateCurrentAddressData: this.updateCurrentAddressData,
                  });
                }}
              />
              <ForageButton
                style={styles.ForageButtonNonFlexView}
                icon="magnify"
                title="Search"
                onPress={(params) => {
                  this.setState({
                    isSearchFilterHidden: !this.state.isSearchFilterHidden,
                  });
                }}
              />
              <ForageButton
                style={styles.ForageButtonNonFlexView}
                icon="currency-usd"
                title="Price Filter"
                onPress={(params) => {
                  this.setState({
                    isPriceFilterHidden: !this.state.isPriceFilterHidden,
                  });
                }}
              />
              <ForageButton
                style={styles.ForageButtonNonFlexView}
                icon="map-marker-distance"
                title="Distance"
                onPress={(params) => {
                  this.setState({
                    isDistanceFilterHidden: !this.state.isDistanceFilterHidden,
                  });
                }}
              />
            </ScrollView>
          </SafeAreaView>

          <RestaurantList
            cart_estimate={this.state.cart_estimate}
            latitude={this.state.latitude}
            longitude={this.state.longitude}
            distance={this.state.distance}
            query={this.state.query}
            onPress={(params) => {
              this.props.navigation.navigate("Restaurant Page", params);
            }}
          />
        </ScrollView>

        {/* Search slide up Panels */}
        <ForageSlideUpPanel isHidden={this.state.isSearchFilterHidden}>
          <Text style={styles.foragePrivacyPolicyHeader1}>
            Search Restaurants
          </Text>
          <ForageSearchBar onSubmitEditing={this.UpdateRestaurantQuery} />
        </ForageSlideUpPanel>

        {/* Price slide up Panels */}
        <ForageSlideUpPanel isHidden={this.state.isPriceFilterHidden}>
          <Text style={styles.foragePrivacyPolicyHeader1}>Price Filter</Text>
          <View style={[{ paddingBottom: 10 }]}>
            <DistanceSlider
              value={this.state.cart_estimate}
              minimumValue={1}
              maximumValue={4}
              title={"Max Price Filter"}
              units={"$"}
              step={1}
              onValueChange={(new_estimate) => {
                this.setState({ cart_estimate: new_estimate });
              }}
            />
          </View>
        </ForageSlideUpPanel>

        {/* Distance slide up Panels */}
        <ForageSlideUpPanel isHidden={this.state.isDistanceFilterHidden}>
          <Text style={styles.foragePrivacyPolicyHeader1}>Distance Filter</Text>
          <View style={[{ paddingBottom: 10 }]}>
            <DistanceSlider
              value={this.metersToMiles(this.state.distance)}
              minimumValue={this.metersToMiles(1600)}
              maximumValue={this.metersToMiles(8000)}
              title={"Distance"}
              units={"Miles"}
              step={1}
              onValueChange={(newDistance) => {
                this.setState({ distance: this.milesToMeters(newDistance) });
              }}
            />
          </View>
        </ForageSlideUpPanel>
      </SafeAreaView>
    );
  }
}
