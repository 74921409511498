import React from "react";
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  Dimensions,
} from "react-native";

import RestaurantTile from "./RestaurantTile";
import ForageButton from "./ForageButton.js";
import styles from "../utils/styles.js";
import styleConst from "../utils/styleConst.js";
import Fire from "../utils/Firebase";

// (TODO) Allow query to be implemented
export default class RestaurantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Parameters and storage
      distance: 10000, // Default distance is 10000
      cart_estimate: 4, // In US dollars
      restaurants: [],
      next_page_token: "",
      restaurantsCount: 0,
      latitude: undefined,
      longitude: undefined,
      query: undefined,

      // Activity monitors for each button
      refreshActivity: false,
      numColumns: Math.max(1, Math.ceil(Dimensions.get("window").width / 400)),
    };

    // Local Functions for generating, deleting and managing the Lists.
    this.LoadMoreRestaurantsFn = this.LoadMoreRestaurantsFn.bind(this);
    this.RefreshRestaurantsFn = this.RefreshRestaurantsFn.bind(this);
    this.CreateRestaurantTile = this.CreateRestaurantTile.bind(this);
    this.RenderRestaurants = this.RenderRestaurants.bind(this);

    // Firebase API wrappers to handle data retrieval.
    this.GetRestaurantsList = this.GetRestaurantsList.bind(this);
    this.GetRestaurantServices = this.GetRestaurantServices.bind(this);
    this.GetRestaurantFees = this.GetRestaurantFees.bind(this);
  }

  componentDidMount() {
    console.log("RestaurantList Mounted");
    if (this.props.distance) {
      this.setState({ distance: this.props.distance });
    } // Set the distance
    if (this.props.cart_estimate) {
      this.setState({ cart_estimate: this.props.cart_estimate });
    }
    if (this.props.latitude) {
      this.setState({ latitude: this.props.latitude });
    }
    if (this.props.longitude) {
      this.setState({ longitude: this.props.longitude });
    }
    if (this.props.query) {
      this.setState({ query: this.props.query });
    }

    this.setState({ restaurants: [] }); // Clear restaurant info list
    this.setState({ next_page_token: "" }); // Clear Next page token
    this.setState({ restaurantsCount: 0 }); // Clear the restaurant count
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.distance) {
      if (this.props.distance != prevState.distance) {
        this.setState({ distance: this.props.distance }); // Set the distance
      }
    }
    if (this.props.cart_estimate) {
      if (this.props.cart_estimate != prevState.cart_estimate) {
        this.setState({ cart_estimate: this.props.cart_estimate }); // Set the cart_estimate
      }
    }
    // Only way to change the lat & lng is to update the props where the
    // component was created.
    if (this.props.latitude && this.props.longitude) {
      if (
        this.props.latitude != prevProps.latitude &&
        this.props.longitude != prevProps.longitude
      ) {
        this.setState(
          {
            latitude: this.props.latitude,
            longitude: this.props.longitude,
          },
          () => {
            this.RefreshRestaurantsFn("Update lat & lng");
          }
        );
      }
    }
    if (this.props.query != prevState.query) {
      this.setState(
        {
          query: this.props.query,
        },
        () => {
          this.RefreshRestaurantsFn("Update query");
        }
      );
    }
  }

  CreateRestaurantTile(
    restaurantInfo,
    restaurantKey,
    onPressFn,
    services,
    services_updated_at
  ) {
    let OnPressCheck = onPressFn;
    if (!onPressFn) {
      OnPressCheck = (param) => {
        console.log(restaurantKey + " was pressed");
      };
    }

    let restaurantTile = (
      <RestaurantTile
        key={restaurantKey}
        restaurantKey={restaurantKey}
        restaurantInfo={restaurantInfo}
        services={services}
        services_updated_at={services_updated_at}
      />
    );

    return (
      <View
        restaurantInfo={restaurantInfo}
        services={services}
        tile={restaurantTile}
        style={styles.RestaurantTileListView}
        key={restaurantKey}
      >
        <TouchableOpacity
          style={styles.RestaurantTileListView}
          onPress={() => {
            OnPressCheck({ restaurantInfo: restaurantInfo });
          }}
        >
          {restaurantTile}
        </TouchableOpacity>
      </View>
    );
  }

  GetRestaurantsList = async (
    latitude,
    longitude,
    radius = 1050,
    page_token = undefined,
    query = undefined
  ) => {
    return new Promise((resolve) => {
      Fire.shared
        .restaurant_search(latitude, longitude, radius, page_token, query)
        .then((response) => {
          console.log("Response:");
          console.log("Num Restaurants: " + response.results.length);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          resolve({ results: [], next_page_token: "" });
        });
    });
  };

  GetRestaurantServices = async (restaurantList) => {
    var newReturnList = [];
    // TODO: We can wait just run them all instead of await for them individually,
    // with the Promise.all( [ Promise, Promise, ...] ) method.
    // https://stackoverflow.com/a/44410328/11244775
    for (let item of restaurantList) {
      let response = await Fire.shared.restaurant_delivery_provider_search(
        item.place_id, // place_id
        false, // uch = false,
        true, // usrp = true,
        false // casf = true
      );

      if (response.services.length) {
        console.log(
          "Found (" + response.services.length + ") Service for " + item.name
        );
        console.log(response.services);
      }
      // Format of services must be tied tightly to and accessible to
      // GetRestaurantFees() method and the corresponding RestaurantTile.
      for (let service_name of response.services) {
        item.services.push({
          service_name: service_name,
          delivery_fee: "N/A",
          delivery_time: "N/A",
          service_fee: "N/A",
        });
      }
      // item.services = response.services;
      newReturnList.push(item);
    }
    return new Promise((resolve) => {
      resolve(newReturnList);
    });
  };

  GetRestaurantFees = async (
    restaurantList,
    latitude,
    longitude,
    radius = 1050,
    query = undefined,
    page_token = undefined
  ) => {
    var newReturnList = [];

    // TODO: Only re-run if we have new parameters. Otherwise we can reuse
    //       previous run and re-use the same information.
    let response = await Fire.shared.restaurant_delivery_fees(
      latitude,
      longitude,
      radius,
      query,
      page_token
    );
    let restaurantFeeList = response.results;

    for (let item of restaurantList) {
      let place_id = item.place_id;
      let foundIndex = restaurantFeeList.findIndex(
        (el) => el.place_id === place_id
      );
      if (foundIndex != -1) {
        let fee_details = restaurantFeeList[foundIndex].fee_details;
        let fees_updated_at = restaurantFeeList[foundIndex].fees_updated_at;

        // Update or Append the services fees found to `item.services`.
        for (var service_name in fee_details) {
          let delivery_fee = fee_details[service_name].delivery_fee;
          let delivery_time = fee_details[service_name].delivery_time;
          let service_fee = fee_details[service_name].service_fee;

          let serviceIndex = item.services.findIndex(
            (el) => el.service_name == service_name
          );
          if (serviceIndex != -1) {
            item.services[serviceIndex].delivery_fee = delivery_fee;
            item.services[serviceIndex].delivery_time = delivery_time;
            item.services[serviceIndex].service_fee = service_fee;
          } else {
            item.services.push({
              service_name: service_name,
              delivery_fee: service_fee,
              delivery_time: delivery_time,
              service_fee: service_fee,
            });
          }
        }

        // Write the date that the fee was updated at to item.services_updated_at.
        item.services_updated_at = fees_updated_at;
      }
      newReturnList.push(item);
    }

    return new Promise((resolve) => {
      resolve(newReturnList);
    });
  };

  async LoadMoreRestaurantsFn() {
    console.log("LoadMoreRestaurantsFn Executed...");
    let radius = this.state.distance;
    let latitude = this.state.latitude;
    let longitude = this.state.longitude;
    let page_token = this.state.next_page_token
      ? this.state.next_page_token
      : undefined;
    let query = this.state.query;
    let num_new_restaurants = 0;

    // Set the activity to active.
    this.setState({ refreshActivity: true });

    // Get the list of restaurants.
    var response = await this.GetRestaurantsList(
      latitude,
      longitude,
      radius,
      page_token,
      query
    );

    // Filter out duplicate restaurants.
    let newRestaurantList = [];
    for (let item of response.results) {
      let name = item.name;
      let place_id = item.place_id;
      // let found = this.state.restaurants.some(el => el.name === name);
      let found = this.state.restaurants.some((el) => el.place_id === place_id);
      item.services = [];
      item.services_updated_at = undefined;
      if (!found) {
        newRestaurantList.push(item);
      }
    }

    // Add any services to any restaurants that may have them.
    var newRestaurantWithServices = await this.GetRestaurantServices(
      newRestaurantList
    );

    // Add any fee information we have
    var newRestaurantWithServices = await this.GetRestaurantFees(
      newRestaurantList,
      latitude,
      longitude,
      radius,
      page_token,
      query
    );

    // Add the new restaurants.
    // this.setState({ restaurants: [...this.state.restaurants, ...newRestaurantWithServices] });
    this.setState({ restaurants: newRestaurantWithServices });

    // Set the next page token.
    this.setState({ next_page_token: response.next_page_token });

    num_new_restaurants = newRestaurantWithServices.length;

    // Set the activity indicator to false.
    this.setState({ refreshActivity: false });

    // For debugging purposes
    console.log("LoadMoreRestaurantsFn finished...");
    console.log("\t radius: " + this.state.distance);
    console.log("\t cart_estimate: " + this.state.cart_estimate);
    console.log(
      "\t bool(page_token): " + (this.state.next_page_token ? true : false)
    );
    console.log("\t query: " + query);
    console.log("\t restaurantsCount: " + this.state.restaurantsCount);
    console.log("\t num_new_restaurants: " + num_new_restaurants);

    var totalFoundServices = 0;
    for (let item of newRestaurantWithServices) {
      if (item.services.length > 0) {
        totalFoundServices += 1;
      }
    }
    console.log("\t totalFoundServices: " + totalFoundServices);
  }

  RefreshRestaurantsFn(reason = "") {
    console.log("RefreshRestaurantsFn Executed... " + reason);
    this.setState(
      {
        restaurants: [], // Clear restaurant info list
        next_page_token: undefined, // Clear Next page token
        restaurantsCount: 0, // Clear the restaurant count
      },
      () => {
        // Load New restaurants AFTER setState is finished
        this.LoadMoreRestaurantsFn();
      }
    );
  }

  RenderRestaurants(restaurant, index, separators) {
    if (restaurant.price_level > this.state.cart_estimate) return <View />;
    let restaurantTile = this.CreateRestaurantTile(
      restaurant,
      restaurant.name, // Using restaurant name as Tile Key
      this.props.onPress,
      restaurant.services,
      restaurant.services_updated_at
    );
    return (
      <View style={styles.RestaurantTileFlatLisView}>{restaurantTile}</View>
    );
  }

  render() {
    return (
      <View style={styles.RestaurantTileListView}>
        {/* Restaurant Tile List */}
        <FlatList
          data={this.state.restaurants}
          renderItem={({ item, index, separators }) =>
            this.RenderRestaurants(item, index, separators)
          }
          numColumns={this.state.numColumns}
          keyExtractor={(item, index) => index}
          onRefresh={() => {
            this.RefreshRestaurantsFn();
          }}
          refreshing={true}
        />
        {this.state.refreshActivity ? (
          <ActivityIndicator
            style={{ padding: 5 }}
            size="large"
            color={styleConst.foragePurple}
          />
        ) : (
          <View />
        )}

        {/* Load More Button */}
        <View style={{ alignItems: "center", paddingVertical: 10 }}>
          <ForageButton
            title="Load More"
            onPress={() => {
              this.LoadMoreRestaurantsFn();
            }}
            color={styleConst.foragePurple}
            fontColor="white"
          />
        </View>
      </View>
    );
  }
}
